import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../../context/AuthContext.tsx";
import useDarkMode from "../../useDarkMode.ts";
import Cookies from "js-cookie";

const LoginForm: React.FC = () => {
  const { login, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const [errorVerify, setErrorVerify] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const isDarkMode = useDarkMode();

  useEffect(() => {
    logout();
  }, [logout]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const errorParam = searchParams.get('error');
    
    if (errorParam) {
      setAlertMessage(decodeURIComponent(errorParam));
      setAlert(true);
    }
  }, [location.search]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");
    setError("");
    setErrorVerify(false);

    if (!email) {
      setEmailError("Please enter an email");
      return;
    }
    if (!email.includes("@")) {
      setEmailError("Please enter a valid email address");
      return;
    }
    if (!password) {
      setPasswordError("Please enter a password");
      return;
    }

    try {
      await login(email, password, rememberMe);
      navigate("/applications");
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);

        if ((error as any).statusCode === 409 || error.message.includes("verify your email")) {
          setErrorVerify(true);
          navigate("/account/verify-form", { state: { email } });
        }
        setAlert(true);
      } else {
        setError("An unexpected error occurred");
        setAlert(true);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = () => {
    setAlert(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Log In to Your Account
        </Typography>
        <Box
          component="form"
          id="login-form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={handleSubmit}
        >
          <TextField
            error={!!emailError || !!error}
            helperText={emailError}
            margin="normal"
            autoFocus
            required
            fullWidth
            type="email"
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            error={!!passwordError || !!error}
            helperText={passwordError || error}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Grid display="flex" alignItems="center" justifyContent="space-between">
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
              }
              label="Remember me"
            />
            <Link
              to="/account/reset-password"
              style={{ textDecoration: "none", color: "blue" }}
            >
              Forgot Password?
            </Link>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid display="flex" alignItems="flex-start" flexDirection="column">
            <Typography variant="body1">
              New to Immigrateable?{" "}
              <Link
                to="/account/signup"
                style={{ textDecoration: "none", color: "blue" }}
              >
                Sign Up
              </Link>
            </Typography>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        open={alert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error">
          {alertMessage || "An error occurred"}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default LoginForm;
