// ContactInfoContext.tsx
import React, {
  createContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from "react";
import { PersonalInfoContext } from "./PersonalInfoContext";
import refreshAccessToken from "../../src/utils/fetchWithToken";


export interface ContactInfo {
  id: string | null;
  userId: string | null;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  country: string;
  currentCountry: string;
  phone: string;
  // userId: string;
}

interface ContactInfoContextType {
  contactInfo: ContactInfo;
  setContactInfo: (
    name: keyof ContactInfo,
    value: ContactInfo[keyof ContactInfo] | undefined,
  ) => void;
  isLoading: boolean;
  resetContactInfo: () => void;
}
const userId = localStorage.getItem("userId");
const id = localStorage.getItem("personalInfoId");
export const defaultContactInfo: ContactInfo = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  postalCode: "",
  country: "",
  currentCountry: "",
  phone: "",
  id: id,
  userId: userId,
  // userId: userID,
};

export const ContactInfoContext = createContext<ContactInfoContextType>({
  contactInfo: defaultContactInfo,
  setContactInfo: () => {},
  isLoading: false,
  resetContactInfo: () => {},
});

export const ContactInfoProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [contactInfo, setContactInfo] =
    useState<ContactInfo>(defaultContactInfo);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const timeoutIdRef = useRef<number | null>(null);
  const { personalInfo } = useContext(PersonalInfoContext);
  const userId = personalInfo.userId;
  const id = personalInfo.id;

  useEffect(() => {
    const contactStore = localStorage.getItem("contactInfo");
    if (contactStore && contactStore !== "undefined") {
      setContactInfo(JSON.parse(contactStore));
    }
  }, []);

  const saveAddressDetails = useCallback(
    async (newInfo: ContactInfo) => {
      setIsLoading(true);
      const id = localStorage.getItem("personalInfoId");
  
      const csrfToken = localStorage.getItem("csrfToken") || "";
      const endPoint = id
        ? `/${import.meta.env.VITE_APPLICATION_URL}/${id}`
        : `/${import.meta.env.VITE_APPLICATION_URL}`;
  
      if (timeoutIdRef.current !== null) {
        clearTimeout(timeoutIdRef.current);
      }
  
      const authToken = localStorage.getItem("authToken");
      const tempToken = localStorage.getItem("tempToken");
  
      const performFetch = async (token: string) => {
        const userID = localStorage.getItem("userId");
        const httpMethod = id ? "PATCH" : "PATCH";
        return fetch(`${import.meta.env.VITE_API_SERVER_URL}${endPoint}`, {
          method: httpMethod,
          credentials: "include",
          headers: {
            verifyTempToken: `${tempToken}`,
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Csrf-Token": csrfToken,
          },
          body: JSON.stringify({
            ...newInfo,
            id: newInfo.id,
            userId: userID,
          }),
        });
      };
  
      timeoutIdRef.current = window.setTimeout(async () => {
        try {
          let response = await performFetch(authToken || "");
          if (response.status === 403) {
            // Token is expired, refresh it
            const newToken = await refreshAccessToken();
            if (newToken) {
              // Retry the request with the new token
              response = await performFetch(newToken);
            }
          }
          
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
  
          const data = await response.json();
          setIsLoading(false);
  
          if (id) {
            setContactInfo((prevAddressInfo) => ({
              ...prevAddressInfo,
              id: data.id,
            }));
          }
        } catch (error) {
          setIsLoading(false);
          console.error("Error saving address details:", error);
        }
      }, 1000);
    },
    [id, userId],
  );
  

  const updateAddressInfo = useCallback(
    (
      name: keyof ContactInfo,
      value: ContactInfo[keyof ContactInfo] | undefined,
    ) => {
      setContactInfo((prevContactInfo) => {
        const updatedContactInfo = { ...prevContactInfo, [name]: value };
        localStorage.setItem("contactInfo", JSON.stringify(updatedContactInfo));
        saveAddressDetails(updatedContactInfo);
        return updatedContactInfo;
      });
    },
    [saveAddressDetails],
  );

  const resetContactInfo = () => {
    setContactInfo(defaultContactInfo);
    localStorage.removeItem("contactInfo"); // Clear from local storage
  };

  return (
    <ContactInfoContext.Provider
      value={{
        contactInfo,
        setContactInfo: updateAddressInfo,
        isLoading,
        resetContactInfo,
      }}
    >
      {children}
    </ContactInfoContext.Provider>
  );
};
