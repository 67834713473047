import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Grid,
  Paper,
  IconButton,
  Typography,
  Button,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Snackbar,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { PersonalInfoContext } from "../../context/PersonalInfoContext";
import { FamilyDetailsContext } from "../../context/FamilyDetailsContext";
import { ContactInfoContext } from "../../context/ContactInfoContext";
import refreshAccessToken from "../../utils/fetchWithToken"; // Adjust the path as needed

export type Application = {
  userId: null;
  firstname: "";
  middlename: "";
  lastname: "";
  dob: null;
  gender: "";
  email: "";
  phone: "";
  city: "";
  country: "";
  applicationId: "24";
  children: [];
  __v: 0;
  id: "";
  dateStarted: "";
  dateUpdated: "";
};
const setTokensToLocalStorage = (url: string) => {
  const urlParams = new URLSearchParams(url.split("?")[1]);

  const accessToken = urlParams.get("accessToken") || "";
  const refreshToken = urlParams.get("refreshToken") || "";

  if (accessToken) {
    localStorage.setItem("authToken", accessToken);
  }

  if (refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);
  }
};

export type ApplicationsProps = {
  _applications: Application[];
};

const authToken = localStorage.getItem("authToken");
// console.log(authToken);

export default function Applications() {
  const [applications, setApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { personalInfo, resetPersonalInfo } = useContext(PersonalInfoContext);
  const { resetFamilyDetails } = useContext(FamilyDetailsContext);
  const { resetContactInfo } = useContext(ContactInfoContext);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const url = window.location.href;
    const urlParams = new URLSearchParams(url.split("?")[1]);
    const accessToken = urlParams.get("accessToken");

    if (accessToken) {
      const loggedIn = localStorage.getItem("authToken"); 
      if (loggedIn) {
        setAlertMessage("Verification Successful!");
        setAlertSeverity("success");
        setOpenSnackbar(true);
      }
    }
  }, []);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const reloadKey = "hasReloaded";
    const lastAccessToken = localStorage.getItem("lastAccessToken");

    // Call the function with the current window location and check if accessToken/refreshToken exists in URL
    const url = window.location.href;
    const urlParams = new URLSearchParams(url.split("?")[1]);
    const accessToken = urlParams.get("accessToken");
    const refreshToken = urlParams.get("refreshToken");

    if (accessToken && accessToken !== lastAccessToken) {
      setTokensToLocalStorage(url);
      localStorage.setItem("lastAccessToken", accessToken);
      localStorage.setItem(reloadKey, "true");
      window.location.reload();
    } else if (refreshToken && !localStorage.getItem(reloadKey)) {
      setTokensToLocalStorage(url);
      localStorage.setItem(reloadKey, "true");
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    // Call the function with the current window location
    setTokensToLocalStorage(window.location.href);
  }, []);
  useEffect(() => {
    const localApplications = localStorage.getItem("applications");
    if (localApplications) {
      setApplications(JSON.parse(localApplications));
    }
    setLoading(false);
  }, []);

  const formatDateForInput = (dateString: string): string => {
    if (!dateString) return "";

    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "";

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}, ${hours}:${minutes}`;
  };

  const getQueryParam = (param: string): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };

  useEffect(() => {
    // Get accessToken and refreshToken from URL
    const accessToken = getQueryParam("accessToken");
    const refreshToken = getQueryParam("refreshToken");

    // Check if authToken and refreshToken are not already in local storage
    if (
      !localStorage.getItem("authToken") &&
      !localStorage.getItem("refreshToken")
    ) {
      if (accessToken) {
        localStorage.setItem("authToken", accessToken);
      }
      if (refreshToken) {
        localStorage.setItem("refreshToken", refreshToken);
      }
    }
  }, []);

  const fetchApplications = async () => {
    console.log("diyor application", authToken);

    try {
      const userId = localStorage.getItem("userId");
      const authToken = localStorage.getItem("authToken");
      const csrfToken = localStorage.getItem("csrfToken") || "";
      let response = await fetch(
        `${import.meta.env.VITE_API_SERVER_URL}/${import.meta.env.VITE_APPLICATION_URL}?userid=${
          userId ? userId : ""
        }`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken,
          },
        },
      );

      if (response.status === 403) {
        console.log("Received 403 status, refreshing token...");
        await refreshAccessToken();
        const newAuthToken = localStorage.getItem("authToken");
        console.log("New auth token:", newAuthToken);

        response = await fetch(
          `${import.meta.env.VITE_API_SERVER_URL}/${import.meta.env.VITE_APPLICATION_URL}?userid=${
            userId ? userId : ""
          }`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              authorization: `Bearer ${newAuthToken}`,
              "Content-Type": "application/json",
              "CSRF-Token": csrfToken,
            },
          },
        );
      }

      const data = await response.json();
      setApplications(data);
    } catch (error) {
      console.error("Error fetching applications:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  const handleAddApplication = useCallback(() => {
    resetPersonalInfo();
    resetContactInfo();
    resetFamilyDetails();
    navigate("/application/personal-details");
  }, [navigate, resetContactInfo, resetFamilyDetails, resetPersonalInfo]);

  const handleEditApplication = useCallback(
    (id: string) => {
      resetPersonalInfo();
      resetContactInfo();
      resetFamilyDetails();
      navigate(`/application/${id}/personal-details`);
      console.log(id);
    },
    [navigate],
  );

  const handleDeleteApplication = async (id: string) => {
    const csrfToken = localStorage.getItem("csrfToken") || "";
  
    try {
      let authToken = localStorage.getItem("authToken");
      let response = await fetch(
        `${import.meta.env.VITE_API_SERVER_URL}/${import.meta.env.VITE_APPLICATION_URL}/${id}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: {
            authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken,
          },
        },
      );
      if (response.status === 403) {
        console.log("Token expired, refreshing...");
        await refreshAccessToken();
        authToken = localStorage.getItem("authToken");
        response = await fetch(
          `${import.meta.env.VITE_API_SERVER_URL}/${import.meta.env.VITE_APPLICATION_URL}/${id}`,
          {
            method: "DELETE",
            credentials: "include",
            headers: {
              authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
              "CSRF-Token": csrfToken,
            },
          },
        );
      }
  
      if (response.ok) {
        setApplications((prev) => prev.filter((app) => app.id !== id));
        console.log("Application deleted successfully.");
      } else {
        console.error("Failed to delete application:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting application:", error);
    }
  };
  

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={2} padding={2}>
      {loading ? (
        <Grid item xs={12} display="flex" justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {applications.length > 0 ? (
            applications.map((app, index) => (
              <Grid item xs={12} key={app.id}>
                <Paper
                  elevation={2}
                  sx={{
                    padding: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: index % 2 ? "#ecf0f1" : "#ffffff",
                  }}
                >
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h6"
                        noWrap
                        sx={{ fontWeight: "bold" }}
                      >
                        {`${app.firstname} ${app.lastname}` || "Unknown name"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        Started: {formatDateForInput(app.dateStarted)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        Updated: {formatDateForInput(app.dateUpdated)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        onClick={() => handleEditApplication(app.id)}
                      >
                        <EditIcon id="edit-button" />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleDeleteApplication(app.id)}
                      >
                        <DeleteIcon id="delete-button" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))
          ) : (
            <Grid item xs={12} display="flex" justifyContent="center">
              <Typography variant="h5" sx={{ color: "text.secondary" }}>
                Add your first application
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            marginTop={2}
          >
            <Button
              onClick={handleAddApplication}
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Add Application
            </Button>
          </Grid>
        </>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
