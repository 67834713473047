import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Button,
  Snackbar,
  Alert,
  TextField,
} from "@mui/material";

const VerifyForm: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState(location.state?.email || "");
  const [verificationSent, setVerificationSent] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const csrfToken = localStorage.getItem("csrfToken");

  // Check for error in the URL parameters
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const errorParam = searchParams.get("error");
    if (errorParam) {
      setErrorMessage(decodeURIComponent(errorParam));
      setAlertError(true);
    }
  }, [location.search]);

  // Email validation function
  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleResendVerificationEmail = async () => {
    if (!email) {
      setErrorMessage("Please enter an email address.");
      setAlertError(true);
      return;
    }

    if (!isValidEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      setAlertError(true);
      return;
    }

    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_SERVER_URL}/${import.meta.env.VITE_RESEND_EMAIL_URL}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken || "",
          },
          body: JSON.stringify({ email }),
          credentials: "include",
        }
      );

      if (response.status === 429) {
        setVerificationSent(false);
        setErrorMessage("We've sent a verification link. Please check your email.");
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to send verification email.");
      }

      setVerificationSent(true);
    } catch (error: any) {
      setErrorMessage(
        error.message ||
          "An unexpected error occurred while sending the verification email."
      );
      setAlertError(true);
    }
  };

  const handleClose = () => {
    setAlertError(false);
  };

  const handleGoBack = () => {
    navigate("/account/login");
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          {verificationSent ? "Verification Email Sent" : "Verify Your Email"}
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
          {verificationSent ? (
            <>
              A verification email has been sent to <strong>{email}</strong>.
              Please check your inbox and follow the instructions.
            </>
          ) : (
            "Please verify your account to sign in."
          )}
        </Typography>
        {!verificationSent && (
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!isValidEmail(email) && email !== ""}
            helperText={!isValidEmail(email) && email !== "" ? "Invalid email address" : ""}
          />
        )}
        {!verificationSent && (
          <Button
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            fullWidth
            onClick={handleResendVerificationEmail}
          >
            Send Verification Email
          </Button>
        )}
        <Button
          variant="outlined"
          sx={{ mb: 2 }}
          fullWidth
          onClick={handleGoBack}
        >
          Back to Login
        </Button>
      </Box>
      <Snackbar
        open={alertError}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage || "An error occurred."}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default VerifyForm;
