import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./layouts/RootLayout.tsx";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import Userdetailsform from "./partials/UserDetailsForm/UserDetailsForm.tsx";
import { PersonalInfoProvider } from "./context/PersonalInfoContext"; // Adjust the path as needed
import { ContactInfoProvider } from "./context/ContactInfoContext";
import { EducationProvider } from "./context/EducationContext";
import { FamilyDetailsProvider } from "./context/FamilyDetailsContext";
import { PhotosProvider } from "./context/PhotosContext";
import { PassportInfoProvider } from "./context/PassportInfoContext";
import { CircularProgress } from "@mui/material";
import ErrorBoundary from "./components/ErrorBoundary/ErroBoundary.tsx";
import { AuthProvider } from "./context/AuthContext.tsx";
import Applications from "./pages/Applications/Applications.tsx";
import HowItWorks from "./pages/HowItWorks/HowItWorks.tsx";
import FAQuestion from "./pages/FAQ/FAQuestion.tsx";
import LoginForm from "./partials/AuthForm/LoginForm.tsx";
import SignUpForm from "./partials/AuthForm/SignUpForm.tsx";
import ResetPasswordForm from "./partials/AuthForm/ResetPasswordForm.tsx";
import WelcomeForm from "./partials/AuthForm/WelcomeForm.tsx";
import Privacy from "./partials/AuthForm/Privacy";
import NotFound from "./components/NotFound/NotFound";
import UpdatePasswordForm from "./partials/AuthForm/UpdatePasswordForm.tsx";
import TermsAndConditions from "./partials/AuthForm/TermsAndConditions";
import Pricing from "./pages/Pricing/Pricing.tsx";
import RefundPolicy from "./partials/AuthForm/RefundPolicy";
import AutoLogInForm from "./partials/AuthForm/AutoLogInForm.tsx";
import Cookies from "js-cookie";
import VerifyForm from "./partials/AuthForm/VerifyForm.tsx";


const fetchData = async (url: string) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include", // Required for cookies to be sent/received
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch data:", error);
    return null;
  }
};

// ... existing code ...

// Add this function to handle UTM parameters
function handleUtmParameters() {
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_id",
    "userID",
  ];

  utmParams.forEach((param) => {
    const value = urlParams.get(param);
    if (value) {
      Cookies.set(param, value, {
        expires: 30,
        domain: `.${window.location.hostname}`,
      }); // Set cookie to expire in 30 days and add . before domain name
    }
  });
}

function getCSRFToken() {
  fetchData(
    `${import.meta.env.VITE_API_SERVER_URL}/${import.meta.env.VITE_CRSF_TOKEN_URL}`,
  ).then((data) => {
    if (!data) {
      console.error("Failed to load data");
      return;
    } else if (!data?.csrfToken) {
      console.error("No CSRF token found in response");
      return;
    }
    console.log("Data loaded:", data);
    localStorage.setItem("csrfToken", data?.csrfToken);

    handleUtmParameters();
  });
}

getCSRFToken();

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <RootLayout />,
      children: [
        {
          path: "",
          element: <App />,
        },
        {
          path: "application",
          element: <Userdetailsform />,
          children: [
            {
              path: ":step",
              element: <Userdetailsform />,
            },
          ],
        },
        {
          path: "how-it-works",
          element: <HowItWorks />,
        },
        {
          path: "faq",
          element: <FAQuestion />,
        },
        {
          path: "pricing",
          element: <Pricing />,
        },
        {
          path: "start",
          element: <div>Start for Free</div>,
        },
        {
          path: "*",
          element: <NotFound />,
        },
        {
          path: "applications",
          element: <Applications />,
        },
        {
          path: "applications/add",
          element: <Userdetailsform />,
          children: [
            {
              path: ":step",
              element: <Userdetailsform />,
            },
          ],
        },
        {
          path: "application/:id",
          element: <Userdetailsform />,
          children: [
            {
              path: ":step",
              element: <Userdetailsform />,
            },
          ],
        },
        {
          path: "account/login",
          element: <LoginForm />,
        },
        {
          path: "account/verify-form",
          element: <VerifyForm />,
        },
        {
          path: "account/signup",
          element: <SignUpForm />,
        },
        {
          path: "account/privacy",
          element: <Privacy />,
        },
        {
          path: "account/refundpolicy",
          element: <RefundPolicy />,
        },
        {
          path: "account/termsandconditions",
          element: <TermsAndConditions />,
        },
        {
          path: "account/welcome",
          element: <WelcomeForm />,
        },
        {
          path: "account/auto-login",
          element: <AutoLogInForm />,
        },
        {
          path: "account/update-password/:token",
          element: <UpdatePasswordForm />,
        },
        {
          path: "account/reset-password",
          element: <ResetPasswordForm />,
        },
        {
          path: "/*",
          element: <App />,
        },
      ],
    },
  ],
  { basename: "/" },
);

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <PersonalInfoProvider>
          <ContactInfoProvider>
            <EducationProvider>
              <FamilyDetailsProvider>
                <PhotosProvider>
                  <PassportInfoProvider>
                    <RouterProvider
                      router={router}
                      fallbackElement={<CircularProgress />}
                    />
                  </PassportInfoProvider>
                </PhotosProvider>
              </FamilyDetailsProvider>
            </EducationProvider>
          </ContactInfoProvider>
        </PersonalInfoProvider>
      </AuthProvider>
    </ThemeProvider>
  </ErrorBoundary>,
  // </React.StrictMode>,
);
